<template>
  <div>
    <!-- 新增员工账号按钮 -->
    <!-- 添加按钮 -->
    <el-row style="text-align: left">
      <el-button type="primary" round @click="insertAdminOpen" display="flex"
        >新增用户</el-button
      >
    </el-row>

    <!-- 用户信息输入框 -->
    <el-dialog
      :visible="insertAdminDialogVisible || updateAdminDialogVisible"
      title="添加商品"
      :before-close="closeAdminDialog"
    >
      <el-form :model="adminForm">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="adminForm.name" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input v-model="adminForm.username" placeholder=""></el-input>
        </el-form-item>
        <!-- <el-form-item label="密码" prop="password">
          <el-input v-model="adminForm.password" placeholder=""></el-input>
        </el-form-item> -->
        <el-form-item label="性别" prop="sex">
          <el-input v-model="adminForm.sex" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="adminForm.phone" placeholder=""></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeAdminDialog">取 消</el-button>
        <el-button
          v-if="insertAdminDialogVisible === true"
          type="primary"
          @click="insertAdminSubmit"
          >确 定</el-button
        >
        <el-button
          v-else-if="updateAdminDialogVisible === true"
          type="primary"
          @click="updateAdminSubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 员工账号信息表 -->
    <el-table :data="users" height="500" border style="width: 100%">
      <el-table-column prop="name" label="姓名" width="200" align="center">
      </el-table-column>
      <el-table-column
        prop="username"
        label="用户名"
        width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="password" label="密码" width="200" align="center">
      </el-table-column>
      <el-table-column prop="sex" label="性别" width="100" align="center">
      </el-table-column>
      <el-table-column prop="phone" label="联系电话" width="210" align="center">
      </el-table-column>
      <el-table-column prop="status" label="账号状态" width="80" align="center">
        <template slot-scope="scope">
          <el-switch
            :value="scope.row.status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="updateAdminActiveSubmit(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            @click="updateAdminOpen(scope.row)"
            type="text"
            size="small"
            style="font-size: 16px; padding: 10px 20px"
            >编辑</el-button
          >
          <el-button
            @click="forbiddenAdminSubmit(scope.row.id)"
            type="text"
            size="small"
            style="font-size: 16px; padding: 10px 20px"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      BASE_URL: "admin/employee/page",
      PAGE_SIZE: 5,
      adminId: -1,
      insertAdminDialogVisible: false,
      updateAdminDialogVisible: false,
      page: 1,
      users: [],
      tempUser: {},
      adminForm: {
        title: '',
        name: "",
        username: "",
        password: "",
        sex: 1,
        phone: "",
        idNumber: "xx",
      },
    };
  },
  methods: {
    handleClick(row) {
      console.log(row);
    },
    // 刷新用户信息表
    flashAdminTable() {
      // 分页获取员工信息
      const url = `${this.BASE_URL}?page=${this.page}&pageSize=${this.PAGE_SIZE}`;
      this.axios
        .get(url)
        .then((response) => {
          console.log("Data received:", response.data);
          this.users = response.data.data.records;
          // console.log("hrae", users);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    // 关闭新增/编辑输入框
    closeAdminDialog() {
      this.insertAdminDialogVisible = false;
      this.updateAdminDialogVisible = false;
      this.$message({
        type: "info",
        message: "已取消操作",
        duration: 1100
      });
    },
    // 新增用户信息入口
    insertAdminOpen() {
      this.adminForm.name = "";
      this.adminForm.username = "";
      this.adminForm.sex = 1;
      this.adminForm.phone = "";
      this.adminForm.idNumber = "xxx";
      this.insertAdminDialogVisible = true; // 打开输入框
    },
    // 新增用户信息提交
    insertAdminSubmit() {
      console.log(this.adminForm);
      this.insertAdminDialogVisible = false; // 关闭输入框
      // 发送新增请求
      // 设置请求头
      const headers = {
        "Content-Type": "application/json",
      };
      this.axios
        .post("/admin/employee", this.adminForm, { headers })
        .then((response) => {
          // 处理响应
          if (response.data.code === 1) {
            console.log("新增成功", response);
            this.$message({
              message: "新用户注册成功",
              type: "success",
              duration: 1100,
            });
            // 更新用户信息表
            this.flashAdminTable();
          } else if (response.data.code === 0) {
            console.log("注册失败");
            this.$message({
              message: "新用户注册失败",
              type: "error",
              duration: 1100,
            });
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("请求失败:", error);
          this.$message({
            message: "请求错误",
            type: "error",
            duration: 1100,
          });
        });
    },
    // 编辑用户信息入口
    updateAdminOpen(user) {
      this.adminForm.name = user.name;
      this.adminForm.username = user.username;
      this.adminForm.sex = 1;
      this.adminForm.phone = user.phone;
      this.adminForm.idNumber = user.idNumber;
      this.updateAdminDialogVisible = true; // 打开输入框
      this.tempUser = user;
      this.title = ''
    },
    // 编辑用户信息提交
    updateAdminSubmit() {
      // 发送编辑请求
      // 设置请求头
      const headers = {
        "Content-Type": "application/json",
      };
      // 设置请求体
      const data = { ...this.adminForm };
      data.id = this.tempUser.id;
      this.axios
        .put("/admin/employee", data, { headers })
        .then((response) => {
          if (response.data.code === 1) {
            this.$message({
              message: "该用户信息修改成功",
              type: "success",
              duration: 1100,
            });
            // 更新用户信息表
            this.tempUser.name = this.adminForm.name;
            this.tempUser.username = this.adminForm.username;
            this.tempUser.sex = this.adminForm.sex;
            this.tempUser.phone = this.adminForm.phone;
          } else if (response.data.code === 0) {
            this.$message({
              message: "该用户信息修改失败",
              type: "error",
              duration: 1100,
            });
          }
        })
        .catch((error) => {
          // 错误处理
          console.log("请求失败", error);
          this.$message({
            message: "请求失败",
            type: "error",
            duration: 1100,
          });
        });
      // 关闭输入框
      this.updateAdminDialogVisible = false;
    },
    // 删除用户账号提交
    deleteAdminSubmit(deleteId) {
      this.$confirm("是否删除该账号", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 停用/启用用户账号提交
    updateAdminActiveSubmit(user) {
      const newStatus = user.status === 1 ? 0 : 1;
      const modeStrs = ["是否禁用该账号", "是否启用该账号"];
      this.$confirm(modeStrs[newStatus], "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          // 发送修改请求
          this.axios({
            url: `/admin/employee/status/${newStatus}`,
            params: { id: user.id },
            method: "post",
            headers: { "Content-Type": "application/json" },
          })
            .then((response) => {
              if (response.data.code === 1) {
                // 修改成功
                user.status = newStatus;
                this.$message({
                  type: "success",
                  message: "操作成功!",
                  duration: 1100,
                });
              } else {
                // 修改错误
                this.$message({
                  type: "error",
                  message: "操作失败!",
                  duration: 1100,
                });
              }
            })
            .catch((error) => {
              console.error("修改请求失败:", error);
              this.$message({
                message: "请求错误",
                type: "error",
                duration: 1100,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
            duration: 1100,
          });
        });
    },
  },
  created() {},
  watch: {
    page: {
      immediate: true,
      handler(value) {
        this.flashAdminTable();
      },
    },
  },
};
</script>

<style>
</style>