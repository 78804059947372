<template>
  <div>
    <el-upload
      action="#"
      list-type="picture-card"
      :file-list="fileList"
      :before-upload="handleImageSelect"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <!-- 显示缩略图 -->
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <!-- 文件操作 -->
        <span class="el-upload-list__item-actions">
          <!-- 预览按钮 -->
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <!-- 下载按钮 -->
          <span
            v-if="disabled"
            class="el-upload-list__item-delete"
            @click="handleDownload(file)"
          >
            <i class="el-icon-download"></i>
          </span>
          <!-- 删除按钮 -->
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    defaultFileUrl: {
      type: String,
      default: ''
    },
    fileChange: Object
  },
  data() {
    return {
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
    };
  },
  methods: {
    handleImageSelect(file) {
      console.log("File list changed:", file);
      // 设置请求头
      const headers = { "Content-Type": "multipart/form-data" };
      // 设置请求体
      const body = { file: file };
      // 发送请求
      this.axios
        .post("/admin/common/upload", body, { headers })
        .then((response) => {
          // 处理响应
          if (response.data.code === 1) {
            console.log("图片提交成功", response);
            this.$message({
              message: "图片提交成功",
              type: "success",
              duration: 1100,
            });
            // 更新图片显示
            this.handleInsert(response.data.data)
          } else if (response.data.code === 0) {
            console.log("图片提交失败");
            this.$message({
              message: "图片提交失败",
              type: "error",
              duration: 1100,
            });
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("请求失败:", error);
          this.$message({
            message: "请求错误",
            type: "error",
            duration: 1100,
          });
        });
      return false;
    },
    // 处理文件删除
    handleRemove() {
      this.fileList.splice(0, this.fileList.length);
    },
    // 处理文件添加
    handleInsert(url){
      this.handleRemove();
      this.fileList.push({url: url});
      // 将新文件URL返回给父组件
      this.fileChange(url);
    },
    // 处理文件预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 处理文件下载
    handleDownload(file) {
      console.log(file);
    },
  },
  mounted(){
    if(this.defaultFileUrl !== ''){
      this.handleInsert(this.defaultFileUrl);
    }
  }
};
</script>