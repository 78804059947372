<template>
  <div>
    <!-- 分类卡片修改框 -->
    <el-dialog
      :visible="updateCategoryDialogVisible"
      title="添加分类"
      :before-close="handleCategoryDialogClose"
    >
      <el-form :model="categoryForm">
        <el-form-item label="分类名" prop="name">
          <el-input v-model="categoryForm.name" placeholder=""></el-input>
        </el-form-item>
      </el-form>
      <el-switch
        v-model="categoryForm.status"
        active-text="启用"
        inactive-text="禁用"
      >
      </el-switch>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCategoryDialogClose">取 消</el-button>
        <el-button type="primary" @click="updatetSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 分类卡片 -->
    <el-card
      :body-style="{ padding: '2px', height: '270px' }"
      shadow="hover"
      @mouseenter.native="handleMouseEnterCard"
      @mouseleave.native="handleMouseLeaveCard"
    >
      <img
        src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
        style="border-radius: 10px; margin-top: 3px;"
        class="image"
      />
      <div style="padding: 14px">
        <span>{{ category.name }}</span>
        <img
          v-if="category.status === 0"
          src="../assets/category_off.png"
          style="
            width: 35px; /* 或者你想要的宽度 */
            height: auto; /* 保持图片的原始宽高比 */
            object-fit: contain;
          "
        /><img />
        <div class="bottom clearfix" :style="bottomStyle">
          <el-button
            type="primary"
            @click="updateCategoryOpen"
            icon="el-icon-edit"
            class="buttom"
          ></el-button>
          <el-button
            type="primary"
            @click="deleteSubmit"
            icon="el-icon-delete"
            class="buttom"
          ></el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    category: Object,
    updatetCategorySubmit: Object,
    deleteCategorySubmit: Object,
    updateCategoryActiveSubmit: Object,
  },
  data() {
    return {
      isMouseOver: false,
      updateCategoryDialogVisible: false,
      categoryForm: {
        id: this.category.id,
        name: this.category.name,
        sort: this.category.sort,
        type: this.category.type,
        status: this.category.status === 1,
      },
    };
  },
  methods: {
    // 处理鼠标移进card
    handleMouseEnterCard() {
      this.isMouseOver = true;
    },
    // 处理鼠标移出card
    handleMouseLeaveCard() {
      this.isMouseOver = false;
    },
    // 打开修改分类输入框
    updateCategoryOpen() {
      this.categoryForm.name = this.category.name;
      this.categoryForm.id = this.category.id;
      this.categoryForm.type = this.category.type;
      this.categoryForm.sort = this.category.sort;
      this.categoryForm.status = this.category.status === 1;
      this.updateCategoryDialogVisible = true;
    },
    // 提交修改分类
    updatetSubmit() {
      this.updateCategoryDialogVisible = false;
      // 判断状态是否改变
      if (this.categoryForm.status !== (this.category.status === 1))
        this.updateCategoryActiveSubmit(
          this.category.id,
          this.categoryForm.status ? 1 : 0
        );
      this.updatetCategorySubmit(this.categoryForm);
    },
    // 关闭修改分类输入框
    handleCategoryDialogClose() {
      this.updateCategoryDialogVisible = false;
      this.$message({
        type: "info",
        message: "已取消操作",
        duration: 1100,
      });
    },
    // 提交删除分类
    deleteSubmit() {
      this.$confirm("是否删除该分类", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          this.deleteCategorySubmit(this.category.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
            duration: 1100,
          });
        });
    },
  },
  computed: {
    bottomStyle() {
      return {
        display: this.isMouseOver ? "block" : "none", // 根据鼠标位置动态改变display属性
        marginTop: "13px",
        marginInline: "20px 20px",
      };
    },
  },
  watch: {
    category: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.categoryForm.name = newVal.name;
        this.categoryForm.id = newVal.id;
        this.categoryForm.type = newVal.type;
        this.categoryForm.sort = newVal.sort;
        this.categoryForm.status = newVal.status === 1;
      },
    },
  },
  mounted() {
    console.log(this.category);
  },
};
</script>

<style scoped>
.el-card {
  margin-top: 10px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: auto;
  height: 60%;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>