<template>
  <div style="margin-top: 15px; margin-bottom: 50px">
    <el-row>
      <el-col :span="4" align="right"
        ><Select
          ref="searchSelect"
          :selectOptions="[
            { name: '订单号', id: 'number' },
            { name: '联系电话', id: 'phone' },
          ]"
          @select-option-click="optionChange"
          :handleClear="orderSearchClear"
        ></Select
      ></el-col>
      <el-col :span="17" :offset="0">
        <el-input
          placeholder="请输入内容"
          v-model="inputText"
          class="input-with-select"
          @blur="inputBlur"
          @keyup.enter.native="enterBlur($event)"
        >
          <el-button
          class="button"
            slot="append"
            icon="el-icon-search"
            @click="doSearch"
          ></el-button>
          <el-button
          class="button"
            slot="append"
            icon="el-icon-delete"
            @click="orderSearchClear"
          ></el-button
        ></el-input>
      </el-col>
      <el-col :span="3"></el-col>
    </el-row>
  </div>
</template>

<script>
import Select from "./Select.vue";
export default {
  components: {
    Select,
  },
  props: {
    orderSearchType: {
      // 搜索信息类别
      type: String,
      default: "",
    },
    orderSearchText: {
      // 搜索信息文本
      type: String,
      default: "",
    },
    change: Function, // 搜索信息改变函数回调，回调参数：改变后的信息对象
    orderDoSearch: Function, // 开始搜索
  },
  data() {
    return {
      selectValue: "",
      inputText: "",
    };
  },
  methods: {
    // 选项点击回调
    optionChange(optionObj) {
      this.selectValue = optionObj.id;
      const newInfo = {
        orderSearchType: this.selectValue,
        orderSearchText: "",
      };
      this.change(newInfo);
    },
    // 输入框输入结束
    inputBlur() {
      const newInfo = {
        orderSearchType: this.selectValue,
        orderSearchText: this.inputText,
      };
      this.change(newInfo);
    },
    // 搜索框清空回调
    orderSearchClear() {
      const newInfo = { orderSearchType: "", orderSearchText: "" };
      this.change(newInfo);
      // this.inputText = '';
      this.doSearch();
    },
    // 搜索信号发出
    doSearch() {
      this.orderDoSearch();
    },
    // 回车失去焦点
    enterBlur(event) {
      event.target.blur();
    },
  },
  watch: {
    orderSearchType: {
      immediate: true,
      handler() {
        this.selectValue = this.orderSearchType;
        this.$nextTick(() => {
          this.$refs.searchSelect.value = this.selectValue;
        });
      },
    },
    orderSearchText: {
      immediate: true,
      handler(newVal) {
        this.inputText = newVal;
      },
    },
  },
  mounted() {},
  beforeDestroy() {},
};
</script>

<style>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.button:hover {
  filter: brightness(0.1);
}
</style>