<template>
  <div>
    <el-tabs type="border-card">
      <el-tab-pane label="所有订单"
        ><h3>所有订单总览</h3>
        <OrderTable :orderStatusStr="'所有订单'" :getOrdersFun="searchOrders">
          <template slot="filter">
            <!-- 检索模块 -->
            <OrderSearchBox
              :orderSearchType="orderSearchType"
              :change="orderSearchInfoChange"
              :orderSearchText="orderSearchText"
              :orderDoSearch="orderDoSearch"
            ></OrderSearchBox>
          </template> </OrderTable
      ></el-tab-pane>
      <el-tab-pane label="代付款"
        ><h3>代付款订单</h3>
        <OrderTable
          :orderStatusStr="'待付款'"
          :getOrdersFun="searchOrders"
          :cancelOrder="cancelOrder"
          ><template slot="filter">
            <!-- 检索模块 -->
            <OrderSearchBox
              :orderSearchType="orderSearchType"
              :change="orderSearchInfoChange"
              :orderSearchText="orderSearchText"
              :orderDoSearch="orderDoSearch"
            ></OrderSearchBox> </template></OrderTable
      ></el-tab-pane>
      <el-tab-pane label="待接单"
        ><h3>待接单订单</h3>
        <OrderTable
          :orderStatusStr="'待接单'"
          :getOrdersFun="searchOrders"
          :cancelOrder="cancelOrder"
          :confirmOrder="confirmOrder"
          ><template slot="filter">
            <!-- 检索模块 -->
            <OrderSearchBox
              :orderSearchType="orderSearchType"
              :change="orderSearchInfoChange"
              :orderSearchText="orderSearchText"
              :orderDoSearch="orderDoSearch"
            ></OrderSearchBox> </template></OrderTable
      ></el-tab-pane>
      <el-tab-pane label="已接单"
        ><h3>已接单订单</h3>
        <OrderTable
          :orderStatusStr="'已接单'"
          :getOrdersFun="searchOrders"
          :cancelOrder="cancelOrder"
          :deliveryOrder="deliveryOrder"
          ><template slot="filter">
            <!-- 检索模块 -->
            <OrderSearchBox
              :orderSearchType="orderSearchType"
              :change="orderSearchInfoChange"
              :orderSearchText="orderSearchText"
              :orderDoSearch="orderDoSearch"
            ></OrderSearchBox> </template></OrderTable
      ></el-tab-pane>
      <el-tab-pane label="派送中"
        ><h3>派送中订单</h3>
        <OrderTable
          :orderStatusStr="'派送中'"
          :getOrdersFun="searchOrders"
          :cancelOrder="cancelOrder"
          :completeOrder="completeOrder"
          ><template slot="filter">
            <!-- 检索模块 -->
            <OrderSearchBox
              :orderSearchType="orderSearchType"
              :change="orderSearchInfoChange"
              :orderSearchText="orderSearchText"
              :orderDoSearch="orderDoSearch"
            ></OrderSearchBox> </template></OrderTable
      ></el-tab-pane>
      <el-tab-pane label="已完成"
        ><h3>已完成订单</h3>
        <OrderTable :orderStatusStr="'已完成'" :getOrdersFun="searchOrders"
          ><template slot="filter">
            <!-- 检索模块 -->
            <OrderSearchBox
              :orderSearchType="orderSearchType"
              :change="orderSearchInfoChange"
              :orderSearchText="orderSearchText"
              :orderDoSearch="orderDoSearch"
            ></OrderSearchBox> </template></OrderTable
      ></el-tab-pane>
      <el-tab-pane label="已取消"
        ><h3>已取消订单</h3>
        <OrderTable :orderStatusStr="'已取消'" :getOrdersFun="searchOrders"
          ><template slot="filter">
            <!-- 检索模块 -->
            <OrderSearchBox
              :orderSearchType="orderSearchType"
              :change="orderSearchInfoChange"
              :orderSearchText="orderSearchText"
              :orderDoSearch="orderDoSearch"
            ></OrderSearchBox> </template></OrderTable
      ></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import OrderSearchBox from "@/components/OrderSearchBox.vue";
import OrderTable from "@/components/OrderTable.vue";
import pubsub from "pubsub-js";
export default {
  components: {
    OrderTable,
    OrderSearchBox,
  },
  data() {
    return {
      orderSearchType: "",
      orderSearchText: "",
    };
  },
  methods: {
    // 分页查询订单
    searchOrders(page, pageSize, status, returnOrders) {
      if (isNaN(page) || isNaN(pageSize) || isNaN(status)) return;
      // 设置请求路径
      let url = `/admin/order/conditionSearch?page=${page}&pageSize=${pageSize}`;
      if (status !== 0) url = url + `&status=${status}`;
      if (this.orderSearchType === "number" || this.orderSearchType === "phone")
        url = url + `&${this.orderSearchType}=${this.orderSearchText}`;
      // 发送请求
      this.axios
        .get(url)
        .then((response) => {
          if (response.data.code === 1) {
            // 返回数据成功
            returnOrders(response.data.data);
          } else if (response.data.code === 0) {
            this.$message({
              message: "订单数据获取失败! " + response.data.msg,
              type: "error",
              duration: 1100,
            });
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("请求失败:", error);
          this.$message({
            message: "请求错误",
            type: "error",
            duration: 1100,
          });
        });
    },
    // 搜索信息改变回调
    orderSearchInfoChange(info) {
      this.orderSearchType = info.orderSearchType;
      this.orderSearchText = info.orderSearchText;
    },
    // 搜索框搜索点击回调
    orderDoSearch() {
      pubsub.publish("orderSearch");
    },
    // 提交取消订单请求
    cancelOrder(cancelOrderId, cancelReason = "商家拒绝出单") {
      // 设置请求头
      const headers = { "Content-Type": "application/json" };
      // 设置请求体
      const body = { cancelReason: cancelReason, id: cancelOrderId };
      this.axios
        .put("/admin/order/cancel", body, { headers })
        .then((response) => {
          if (response.data.code === 1) {
            // 取消成功，发布消息通知订单已修该
            pubsub.publish("orderUpdate");
            console.log("订单取消成功");
            this.$message({
              message: "订单取消成功",
              type: "success",
              duration: 1100,
            });
          } else if (response.data.code === 0) {
            console.log("订单取消失败");
            this.$message({
              message: "订单取消失败",
              type: "success",
              duration: 1100,
            });
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("请求失败:", error);
          this.$message({
            message: "请求错误",
            type: "error",
            duration: 1100,
          });
        });
    },
    // 提交完成订单请求
    completeOrder(completeOrderId) {
      if (isNaN(completeOrderId)) return;
      // 发送请求
      this.axios
        .put(`/admin/order/complete/${completeOrderId}`)
        .then((response) => {
          if (response.data.code === 1) {
            // 完成订单成功，发布消息通知订单已修该
            pubsub.publish("orderUpdate");
            console.log("订单完成提交成功");
            this.$message({
              message: "订单完成成功",
              type: "success",
              duration: 1100,
            });
          } else if (response.data.code === 0) {
            console.log("订单完成提交失败");
            this.$message({
              message: "订单完成提交失败",
              type: "success",
              duration: 1100,
            });
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("请求失败:", error);
          this.$message({
            message: "请求错误",
            type: "error",
            duration: 1100,
          });
        });
    },
    // 提交派送订单请求
    deliveryOrder(deliveryOrderId) {
      if (isNaN(deliveryOrderId)) return;
      // 发送请求
      this.axios
        .put(`/admin/order/delivery/${deliveryOrderId}`)
        .then((response) => {
          if (response.data.code === 1) {
            // 派送订单成功，发布消息通知订单已修该
            pubsub.publish("orderUpdate");
            console.log("该订单开始配送");
            this.$message({
              message: "该订单开始配送",
              type: "success",
              duration: 1100,
            });
          } else if (response.data.code === 0) {
            console.log("派送订单提交失败");
            this.$message({
              message: "派送订单提交未通过",
              type: "success",
              duration: 1100,
            });
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("请求失败:", error);
          this.$message({
            message: "请求错误",
            type: "error",
            duration: 1100,
          });
        });
    },
    // 提交接收订单请求
    confirmOrder(confirmOrderId) {
      if (isNaN(confirmOrderId)) return;
      // 设置请求头
      const headers = {'Content-Type': 'application/json'};
      // 设置请求体
      const body = {id: confirmOrderId};
      // 发送请求
      this.axios
        .put(`/admin/order/confirm`, body, {headers})
        .then((response) => {
          if (response.data.code === 1) {
            // 接收订单成功，发布消息通知订单已修该
            pubsub.publish("orderUpdate");
            console.log("接收订单成功");
            this.$message({
              message: "接收订单成功",
              type: "success",
              duration: 1100,
            });
          } else if (response.data.code === 0) {
            console.log("接收订单失败");
            this.$message({
              message: "接收订单未通过",
              type: "success",
              duration: 1100,
            });
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("请求失败:", error);
          this.$message({
            message: "请求错误",
            type: "error",
            duration: 1100,
          });
        });
    },
  },
  computed: {
    // allOrders() {
    //   // 所有订单
    //   return this.currentOrders;
    // },
    // waitPayOrders() {
    //   // 待付款
    //   return this.currentOrders.filter((order) => order.status === 1);
    // },
    // waitConfirmOrders() {
    //   // 待接单
    //   return this.currentOrders.filter((order) => order.status === 2);
    // },
    // confirmOrders() {
    //   // 已接单
    //   return this.currentOrders.filter((order) => order.status === 3);
    // },
    // deliveryOrders() {
    //   // 派送中
    //   return this.currentOrders.filter((order) => order.status === 4);
    // },
    // completeOrders() {
    //   // 已完成
    //   return this.currentOrders.filter((order) => order.status === 5);
    // },
    // cancelOrders() {
    //   // 已取消
    //   return this.currentOrders.filter((order) => order.status === 6);
    // },
  },
  watch: {
    orderSearchType: {
      immediate: true,
      deep: true,
      handler() {
        // this.orderDoSearch();
      },
    },
    orderSearchText: {
      immediate: true,
      deep: true,
      handler() {
        // this.orderDoSearch();
      },
    },
  },
  mounted() {},
};
</script>

<style>
.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>