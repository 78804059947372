<template>
  <div>
    <el-button type="text" @click="showUserInfoDialog()" align="center"
      >查看用户详情</el-button
    >
    <!-- 用户信息显示框 -->
    <el-dialog
      :visible="userInfoDialogVisible"
      :before-close="closeUserInfoDialog"
      align="center"
    >
      <div style="height: 150px;">
        <el-descriptions
        class="margin-top"
        title="用户信息"
        :column="3"
        border
      >
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            用户名
          </template>
          {{ user.name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            手机号
          </template>
          {{ user.phone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            性别
          </template>
          <el-tag size="small">{{ SEX_STRS[user.sex] }}</el-tag>
        </el-descriptions-item>
      </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    userId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      SEX_STRS: ['女', '男'],
      userInfoDialogVisible: false, // 是否购物车信息对话框显示
      user: {},
    };
  },
  methods: {
    // 显示订单购物车信息
    showUserInfoDialog() {
      this.userInfoDialogVisible = true;
    },
    // 隐藏订单购物车信息
    closeUserInfoDialog() {
      this.userInfoDialogVisible = false;
    },
    // 更新购物单
    flashUserInfo() {
      if (isNaN(this.userId)) return;
      // 发送请求
      this.axios
        .get(`/admin/user/${this.userId}`)
        .then((response) => {
          if (response.data.code === 1) {
            this.user = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    userId: {
      immediate: true,
      handler() {
        this.flashUserInfo();
      },
    },
  },
  mounted() {},
};
</script>

<style>
</style>