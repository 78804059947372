<template>
  <div>
    <el-button type="text" @click="showOrderInfoDialog()" align="center"
      >订单详情</el-button
    >
    <el-dialog
      :visible="OrderInfoDialogVisible"
      :before-close="closeOrderInfoDialog"
    >
      <div style="height: 600px; width: 99%">
        <el-descriptions title="订单详情" :column="100" border :size="medium">
          <!-- 订单号 -->
          <el-descriptions-item :span="100">
            <template slot="label">
              <i class="el-icon-bank-card"></i><span>订单号</span>
            </template>
            <span>{{ order.number }}</span>
          </el-descriptions-item>
          <!-- 订单状态、支付方式、支付状态 -->
          <el-descriptions-item :span="50" contentStyle="width: 20%;">
            <template slot="label">
              <i class="el-icon-bank-card"></i><span>订单状态</span>
            </template>
            <span>{{ STATUS_STR[order.status] }}</span>
          </el-descriptions-item>
          <el-descriptions-item :span="25">
            <template slot="label">
              <i class="el-icon-bank-card"></i><span>支付方式</span>
            </template>
            <span>{{ PAY_METHOD_STR[order.payMethod] }}</span>
          </el-descriptions-item>
          <el-descriptions-item :span="25">
            <template slot="label">
              <i class="el-icon-bank-card"></i><span>支付状态</span>
            </template>
            <span>{{ order.payStatus ? "已支付" : "未支付" }}</span>
          </el-descriptions-item>
          <!-- 购物信息、总金额 -->
          <el-descriptions-item
            :span="75"
            contentStyle="word-break: 'break-all'; max-width: 200px;"
          >
            <template slot="label">
              <i class="el-icon-shopping-cart-1"></i><span>购买商品</span>
            </template>
            <span>{{ order.orderSnackes }}</span>
          </el-descriptions-item>
          <el-descriptions-item :span="25">
            <template slot="label"
              ><i class="el-icon-money"></i> <span>总金额</span></template
            >
            <span>{{ order.amount }}</span>
          </el-descriptions-item>
          <!-- 收货人、联系电话 -->
          <el-descriptions-item :span="50">
            <template slot="label"
              ><i class="el-icon-s-custom"></i> <span>收货人</span></template
            >
            <span>{{ order.consignee }}</span>
          </el-descriptions-item>
          <el-descriptions-item :span="50">
            <template slot="label"
              ><i class="el-icon-phone"></i> <span>联系电话</span></template
            >
            <span>{{ order.phone }}</span>
          </el-descriptions-item>
          <!-- 收货地址 -->
          <el-descriptions-item
            :span="100"
            contentStyle="word-break: 'break-all'; max-width: 400px;"
          >
            <template slot="label"
              ><i class="el-icon-location-information"></i>
              <span>收货地址</span></template
            >
            <span>{{ order.address }}</span>
          </el-descriptions-item>
          <!-- 下单时间、支付时间/取消时间 -->
          <el-descriptions-item :span="50">
            <template slot="label"
              ><i class="el-icon-time"></i> <span>下单时间</span></template
            >
            <span>{{ order.orderTime }}</span>
          </el-descriptions-item>
          <el-descriptions-item :span="50">
            <template slot="label"
              ><i class="el-icon-time"></i>
              <span>{{
                order.status === 6 ? "取消时间" : "支付时间"
              }}</span></template
            >
            <span>{{
              order.status === 6 ? order.cancelTime : order.checkoutTime
            }}</span>
          </el-descriptions-item>
          <template v-if="order.status !== 6 && order.status !== 1">
            <!-- 预计送达时间、送达时间 -->
            <el-descriptions-item :span="50">
              <template slot="label"
                ><i class="el-icon-alarm-clock"></i>
                <span>预计送达时间</span></template
              >
              <span>{{ order.estimatedDeliveryTime }}</span>
            </el-descriptions-item>
            <el-descriptions-item :span="50">
              <template slot="label"
                ><i class="el-icon-timer"></i> <span>送达时间</span></template
              >
              <span>{{ order.deliveryTime }}</span>
            </el-descriptions-item>
          </template>
          <template v-if="order.status === 6">
            <!-- 取消原因 -->
            <el-descriptions-item :span="100">
              <template slot="label"
                ><i class="el-icon-info"></i> <span>取消原因</span></template
              >
              <span>{{ order.cancelReason }}</span>
            </el-descriptions-item>
          </template>
          <!-- 备注 -->
          <el-descriptions-item
            :span="100"
            contentStyle="word-break: 'break-all'; max-width: 600px;"
          >
            <template slot="label"
              ><i class="el-icon-s-comment"></i> <span>备注</span></template
            >
            <el-input
              readonly
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5 }"
              placeholder="请输入内容"
              value="order.remark"
            >
            </el-input>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: { order: { type: Object, default: {} } },
  data() {
    return {
      STATUS_STR: [
        "所有订单",
        "待付款",
        "待接单",
        "已接单",
        "派送中",
        "已完成",
        "已取消",
      ],
      PAY_METHOD_STR: ["", "微信支付", "支付宝支付"],
      OrderInfoDialogVisible: false,
    };
  },
  methods: {
    // 打开订单详情对话框
    showOrderInfoDialog() {
      this.OrderInfoDialogVisible = true;
    },
    // 关闭订单详情对话框
    closeOrderInfoDialog() {
      this.OrderInfoDialogVisible = false;
    },
    test() {},
  },
  watch: {
    order: {
      immediate: true,
      handler() {},
    },
  },
  mounted() {},
};
</script>

<style>
</style>