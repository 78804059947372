<template>
  <div>
    <!-- 搜索模块插槽 -->
    <slot name="filter"></slot>

    <el-table :data="orders" style="width: 100%" align="center">
      <el-table-column
        prop="number"
        label="订单号"
        width="180px"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="userId" label="用户" width="100px" align="center">
        <template slot-scope="scope">
          <UserInfoDialog :userId="scope.row.userId"></UserInfoDialog>
        </template>
      </el-table-column>
      <el-table-column
        prop="phone"
        label="联系电话"
        width="180px"
        align="center"
      ></el-table-column>
      <el-table-column prop="id" label="购物车" width="120px" align="center"
        ><template slot-scope="scope">
          <OrderDetailListDialog
            :orderId="scope.row.id"
          ></OrderDetailListDialog> </template
      ></el-table-column>
      <el-table-column
        prop="amount"
        label="订单金额"
        width="110px"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="consignee"
        label="收货人"
        width="120px"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="address"
        label="送货地址"
        width="190px"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="status"
        label="订单状态"
        width="100px"
        align="center"
      >
        <template scope="scope"
          ><el-tag :style="tagStyle(scope.row)">{{
            STATUS_STR[scope.row.status]
          }}</el-tag></template
        ></el-table-column
      >
      <el-table-column
        prop="orderTime"
        label="下单时间"
        width="160px"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" width="220px" align="center"
        ><template slot-scope="scope">
          <div style="display: flex; justify-content: center">
            <!-- 订单详情 -->
            <div style="width: 60px; margin-right: 6px; margin-left: 6px">
              <OrderInfoDialog :order="scope.row"></OrderInfoDialog>
            </div>
            <!-- 接收订单 -->
            <div
              style="width: 60px; margin-right: 6px; margin-left: 6px"
              v-if="confirmButtonVisible"
            >
              <el-button
                type="text"
                @click="orderConfirmClick(scope.row.id)"
                align="center"
                >接收订单</el-button
              >
            </div>
            <!-- 派送订单 -->
            <div
              style="width: 60px; margin-right: 6px; margin-left: 6px"
              v-if="deliveryButtonVisible"
            >
              <el-button
                type="text"
                @click="orderDeliveryClick(scope.row.id)"
                align="center"
                >派送订单</el-button
              >
            </div>
            <!-- 完成订单 -->
            <div
              style="width: 60px; margin-right: 6px; margin-left: 6px"
              v-if="completeButtonVisible"
            >
              <el-button
                type="text"
                @click="orderCompleteClick(scope.row.id)"
                align="center"
                >完成订单</el-button
              >
            </div>
            <!-- 取消订单 -->
            <div
              style="width: 60px; margin-right: 6px; margin-left: 6px"
              v-if="cancelButtonVisible"
            >
              <el-button
                type="text"
                @click="orderCancelClick(scope.row.id)"
                align="center"
                >取消订单</el-button
              >
            </div>
          </div>
        </template></el-table-column
      >
    </el-table>

    <!-- 分页模块 -->
    <div style="text-align: center">
      <el-pagination
        layout="prev, pager, next"
        :total="orderTotal"
        :page-size="PAGE_SIZE"
        @prev-click="handlePrevPage"
        @next-click="handleNextPage"
        @current-change="handleChangePage"
        style="margin-top: 50px"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import OrderDetailListDialog from "./OrderDetailListDialog.vue";
import UserInfoDialog from "@/components/UserInfoDialog.vue";
import OrderInfoDialog from "./OrderInfoDialog.vue";
import pubsub from "pubsub-js";
export default {
  components: {
    OrderDetailListDialog,
    UserInfoDialog,
    OrderInfoDialog,
  },
  props: {
    orderStatusStr: {
      type: String,
      default: "",
    },
    getOrdersFun: Function, // 回调参数：页数、页大小、status、订单数据回调函数
    cancelOrder: {
      // 回调参数：要取消的订单id 取消订单原因
      type: Function,
      required: false,
    },
    completeOrder: {
      // 回调参数： 要完成的订单id
      type: Function,
      required: false,
    },
    deliveryOrder: {
      // 回调参数： 要派送的订单id
      type: Function,
      required: false,
    },
    confirmOrder: {
      // 回调参数：要接收的订单id
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      STATUS_STR: [
        "所有订单",
        "待付款",
        "待接单",
        "已接单",
        "派送中",
        "已完成",
        "已取消",
      ],
      confirmButtonVisible: false, // 是否接收订单按钮显示
      deliveryButtonVisible: false, // 是否派送订单按钮显示
      completeButtonVisible: false, // 是否完成订单按钮显示
      cancelButtonVisible: false, // 是否取消订单按钮显示
      PAGE_SIZE: 8,
      page: 1,
      orders: [],
      orderTotal: 0,
    };
  },
  methods: {
    // 处理订单状态样式
    tagStyle(row) {
      if (row.status === 1) {
        return "background-color: #f9f9f6; color: #161616;";
      } else if (row.status === 2) {
        return "background-color: #faebd7; color: #161616;";
      } else if (row.status === 3) {
        return "background-color: #dff0d8; color: #161616";
      } else if (row.status === 4) {
        return "background-color: #409EFF; color: #161616;";
      } else if (row.status === 5) {
        return "background-color: #7BF324 ; color: #161616;";
      } else if (row.status === 6) {
        return "background-color: #dfdddd; color: #161616;";
      }
      return "";
    },
    // 分页更新表数据
    flashOrders() {
      let status = this.STATUS_STR.indexOf(this.orderStatusStr);
      this.getOrdersFun(this.page, this.PAGE_SIZE, status, this.setOrders);
    },
    // 上一页
    handlePrevPage(){
      this.page--;
    },
    // 下一页
    handleNextPage(){
      this.page++;
    },
    // 指定页数
    handleChangePage(newPage){
      this.page = newPage;
    },
    setOrders(newOrders) {
      this.orders = newOrders.records;
      this.orderTotal = newOrders.total;
    },
    // 订单取消按钮回调
    orderCancelClick(cancelOrderId) {
      this.$prompt("请输入取消订单原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[^\t\r\n]+$/,
        inputErrorMessage: "输入不能为空",
      })
        .then(({ value }) => {
          console.log("订单取消Id：" + cancelOrderId);
          this.cancelOrder(cancelOrderId, value);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "操作取消",
          });
        });
    },
    // 订单完成按钮回调
    orderCompleteClick(completeOrderId) {
      this.$confirm("该订单是否确认", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.completeOrder(completeOrderId);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "操作取消",
            duration: 1100,
          });
        });
    },
    // 订单派送按钮回调
    orderDeliveryClick(deliveryOrderId) {
      this.$confirm("确认该订单开始派送", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deliveryOrder(deliveryOrderId);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "操作取消",
            duration: 1100,
          });
        });
    },
    // 订单接收按钮回调
    orderConfirmClick(confirmOrderId) {
      this.$confirm("确认接收该订单", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.confirmOrder(confirmOrderId);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "操作取消",
            duration: 1100,
          });
        });
    },
  },
  watch: {
    page: {
      immediate: true,
      handler(){
        this.flashOrders();
      }
    }
  },
  computed: {},
  mounted() {
    // 判断订单表格类型
    if (this.orderStatusStr === "待接单") {
      this.confirmButtonVisible = true; // 接收订单按钮
      this.cancelButtonVisible = true; // 取消订单按钮
    } else if (this.orderStatusStr === "已接单") {
      this.deliveryButtonVisible = true; // 派送订单按钮
      this.cancelButtonVisible = true; // 取消订单按钮
    } else if (this.orderStatusStr === "派送中") {
      this.completeButtonVisible = true; // 完成订单按钮
      this.cancelButtonVisible = true; // 取消订单按钮
    } else if (this.orderStatusStr === "待付款") {
      this.cancelButtonVisible = true; // 取消订单按钮
    }
    // 动态订单数据
    this.flashOrders();
    // 订阅消息
    this.pid = pubsub.subscribe("orderSearch", this.flashOrders);
    this.pid1 = pubsub.subscribe("orderUpdate", this.flashOrders);
  },
  beforeDestroy() {
    // 取消订阅
    pubsub.unsubscribe(this.pid);
    pubsub.unsubscribe(this.pid);
  },
};
</script>

<style>
.test {
  background-color: #161616;
}
</style>