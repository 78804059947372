<template>
  <div>
    <el-button
      type="text"
      @click="showOrderDetailListDialog"
      align="center"
      >查看购物详情</el-button
    >
    <!-- 购物车信息显示框 -->
    <el-dialog
      :visible="orderDetailListDialogVisible"
      :before-close="closeOrderDetailListDialog"
      align="center"
    >
      <!-- 购物单 -->
      <el-table
        :data="orderDetailList"
        height="500"
        style="width: 661px"
        align="left"
      >
        <el-table-column
          prop="image"
          label="商品图片"
          width="120px"
          align="center"
        >
          <template slot-scope="scope">
            <el-image
              style="height: 80px; margin: 0px; padding: 0px"
              :src="scope.row.image"
              fit="scale-down"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="商品名称"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="amount"
          label="商品价格"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="number" label="数量" width="180" align="center">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    orderId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      orderDetailListDialogVisible: false, // 是否购物车信息对话框显示
      orderDetailList: [], // 购物单
    }
  },
  methods: {
    // 显示订单购物车信息
    showOrderDetailListDialog() {
      this.orderDetailListDialogVisible = true;
    },
    // 隐藏订单购物车信息
    closeOrderDetailListDialog() {
      this.orderDetailListDialogVisible = false;
    },
    // 更新购物单
    flashOrderDetailList(){
      if(this.orderId === -1 || isNaN(this.orderId)) return;
      // 发送请求
      this.axios.get(`/admin/order/detail/${this.orderId}`)
      .then((response) => {
        if(response.data.code === 1){
          this.orderDetailList = response.data.data.orderDetailList;
        }
      })
      .catch((error) => {
        console.log(error);
      })
    }
  },
  watch: {
    orderId: {
      immediate: true,
      handler(){
        this.flashOrderDetailList();
      }
    }
  },
  mounted(){

  }
};
</script>

<style>
</style>