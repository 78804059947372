<template>
  <el-select v-model="value" :placeholder="placeholder" clearable @clear="handleClear">
    <el-option
      v-for="item in selectOptions"
      :key="item.id"
      :label="item.name"
      :value="item.id"
      @click.native="handleSelectOptionClick(item)"
    >
    </el-option>
  </el-select>
</template>

<script>

export default {
  props: {
    selectOptions: Array,
    handleClear: Function, // 清空选项
    placeholder: String
  },
  data() {
    return {
      value: ''
    };
  },
  methods: {
    handleSelectOptionClick(item){ // 回调参数：被点击的选项对象
      this.$emit('select-option-click', item);
    }
  },
  mounted(){
    console.log(this.selectOptions);
  }
};
</script>

<style scoped>
</style>