<template>
  <el-container style="height: 100%">
    <el-header
      style="height: 70px; width: 100%; background-color: rgb(74, 82, 90)"
    >
      <div style="width: 100%; display: flex; justify-content: space-between">
        <div
          style="width: 80%; margin-top: -15px; color: #fff; text-align: left"
        >
          <h2>
            "美味直达"后台管理系统
            <!-- 店铺状态 -->
            <template v-if="storeStatus === 1">
              <i
                class="el-icon-sunny"
                style="font-size: 30px; margin-left: 25px"
              ></i
              ><span
                ><a href="#" @click.prevent="updateStoreStatus" style="color: sandybrown"
                  >营业中</a
                ></span
              >
            </template>
            <template v-if="storeStatus === 0">
              <i
                class="el-icon-moon"
                style="font-size: 30px; margin-left: 25px"
              ></i
              ><span
                ><a href="#" @click.prevent="updateStoreStatus" style="color: sandybrown"
                  >歇业中</a
                ></span
              >
            </template>
          </h2>
        </div>
        <div style="width: 20%; text-align: right; margin-right: 20px">
          <el-dropdown
            @command="handleCommand"
            trigger="click"
            placement="bottom-start"
          >
            <i
              class="el-icon-setting"
              style="
                color: #fff;
                margin-right: 15px;
                margin-top: 25px;
                font-size: 20px;
              "
            ></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span style="color: #fff; font-size: 20px">{{ admin.name }}</span>
        </div>
      </div>
    </el-header>

    <el-container>
      <el-aside width="200px" style="background-color: rgb(94, 102, 110)">
        <el-menu
          :default-active="activeIndex"
          :default-openeds="['1', '2']"
          class="el-menu-vertical-demo"
          @select="handleClick"
          :unique-opened="false"
          router="true"
          background-color="rgb(84,92,100)"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <el-menu-item><strong>系统菜单</strong></el-menu-item>
          <el-submenu index="1">
            <template slot="title"
              ><i class="el-icon-tickets"></i
              ><strong>业务管理</strong></template
            >
            <el-menu-item index="/Home/SnackManage">商品管理</el-menu-item>
            <el-menu-item index="/Home/CategoryManage">分类管理</el-menu-item
            ><el-menu-item index="/Home/OrderManage">订单管理</el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-user"></i>
              <strong>账号管理</strong></template
            >
            <el-menu-item index="/Home/AdminManage">员工管理</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>

      <div style="background-color: #f5f5f5; width: 100%; height: 100%">
        <el-main>
          <router-view></router-view>
        </el-main>
      </div>
    </el-container>
  </el-container>
</template>

<style>
.el-header {
  /* #b3c0d1 */
  background-color: rgb(red, green, blue);
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
/* rgb(84,92,100, 0.2) */
.el-menu-item {
  background-color: rgba(13, 108, 204, 0.2);
}

.el-main {
  padding: 0px;
  margin: 30px 55px 10px 55px;
  background-color: rgb(255, 255, 255);
}

body,
html {
  height: 100%;
}
</style>

<script>
import pubsub from "pubsub-js";
export default {
  data() {
    return {
      storeStatus: 0,
      adminId: -1,
      admin: {},
      isReflesh: true,
      defaultOpeneds: [],
      // WebSocket
      reconnectAttempts: 0,
      MAX_CONNECT_COUNT: 5,
      reconnectInterval: 5000,
      websocket: null,
      websocketUrl: "47.97.71.235:7070",
    };
  },
  methods: {
    handleCommand(command) {
      if (command === "logout") {
        this.outLogin();
      }
    },
    // 退出登录
    outLogin() {
      // 清空token
      localStorage.setItem("token", "");
      // 删除store
      localStorage.removeItem("store");
      // 跳转到登录界面
      this.$router.push({
        name: "Login",
      });
    },
    //连接发生错误的回调方法
    onerror(e) {
      console.log("WebSocket连接错误", e);
    },
    //连接成功建立的回调方法
    onopen(e) {
      console.log("WebSocket连接成功", e);
    },
    //接收到消息的回调方法
    onmessage(e) {
      console.log("WebSocket收到消息", e.data);
      const data = JSON.parse(e.data);
      if (data.type === 2) {
        // 催单了
        this.userOrderReminder(data.number, data.orderId);
      } else if (data.type === 1) {
        // 有新的订单
        this.newOrderReminder(data.number, data.orderId);
      } else if (data.type === 3) {
        // 有订单取消
        this.cancelOrderReminder(data.number, data.orderId);
      }
    },
    //连接关闭的回调方法
    onclose(e) {
      console.log("WebSocket断开连接", e);
      if (this.reconnectAttempts < this.MAX_CONNECT_COUNT) {
        // 尝试重连
        setTimeout(() => {
          console.log(`尝试第 ${this.reconnectAttempts + 1} 次重连...`);
          this.reconnectWebSocket();
        }, this.reconnectInterval);
        this.reconnectAttempts++;
      } else {
        console.log("重连失败，已达最大尝试次数");
      }
    },
    // 重新连接WebSocket
    reconnectWebSocket() {
      this.websocket = new WebSocket(`ws://${this.websocketUrl}/ws/10086`);
    },
    // 用户催单
    userOrderReminder(number, orderId) {
      this.$notify({
        title: "提醒",
        message: `订单号为${number}催单了，请尽快送达`,
        type: "warning",
        duration: 0,
      });
    },
    // 有新的订单
    newOrderReminder(number, orderId) {
      this.$notify({
        title: "提醒",
        message: `有新的订单${number}，请尽快处理`,
        type: "warning",
        duration: 0,
      });
      pubsub.publish("orderUpdate");
    },
    // 有订单取消
    cancelOrderReminder(number, orderId) {
      this.$notify({
        title: "提醒",
        message: `订单${number}已取消，请确认`,
        type: "warning",
        duration: 0,
      });
      pubsub.publish("orderUpdate");
    },
    // 获取店铺状态
    getStoreStatus() {
      this.axios
        .get("/admin/shop/status")
        .then((response) => {
          if (response.data.code === 1) {
            this.storeStatus = response.data.data;
          } else {
            this.$message({
              message: "店铺状态获取失败",
              type: "error",
              duration: 1100,
            });
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("请求失败:", error);
          this.$message({
            message: "请求错误",
            type: "error",
            duration: 1100,
          });
        });
    },
    // 修改店铺状态
    updateStoreStatus() {
      this.$confirm(
        `店铺状态是否改为${this.storeStatus === 0 ? "营业中" : "歇业中"}`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          // 设置请求头
          const headers = { "Content-Type": "application/json" };
          // 发送请求
          this.axios
            .put(
              `/admin/shop/${this.storeStatus === 1 ? 0 : 1}`,
              {},
              { headers }
            )
            .then((response) => {
              if (response.data.code === 1) {
                this.storeStatus = this.storeStatus === 1 ? 0 : 1;
                this.$message({
                  message: "店铺状态修改成功",
                  type: "success",
                  duration: 1100,
                });
              } else {
                this.$message({
                  message: "店铺状态失败",
                  type: "error",
                  duration: 1100,
                });
              }
            })
            .catch((error) => {
              // 处理错误
              console.error("请求失败:", error);
              this.$message({
                message: "请求错误",
                type: "error",
                duration: 1100,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "操作取消",
          });
        });
    },
  },
  computed: {
    activeIndex() {
      return this.$route.path.replace();
    },
  },
  watch: {
    websocket: {
      immediate: true,
      handler() {
        this.websocket.onerror = this.onerror;
        this.websocket.onopen = this.onopen;
        this.websocket.onmessage = this.onmessage;
        this.websocket.onclose = this.onclose;
        this.reconnectAttempts = 0;
      },
    },
  },
  mounted() {
    console.log("store", this.$store.state);
    this.adminId = this.$store.state.adminId;
    // 根据员工id获取账号信息
    this.axios
      .get(`/admin/employee/${this.adminId}`)
      .then((response) => {
        if (response.data.code === 1) {
          this.admin = response.data.data;
        } else if (response.data.code === 0) {
          this.$message({
            message: "账号信息获取失败，请重新登录",
            type: "error",
            duration: 1100,
          });
        }
      })
      .catch((error) => {
        // 处理错误
        console.error("请求失败:", error);
        this.$message({
          message: "请求错误",
          type: "error",
          duration: 1100,
        });
      });
    // WebSocket连接
    this.reconnectWebSocket();
    // 获取店铺信息
    this.getStoreStatus();
  },
};
</script>