<template>
  <div>
    <div style="margin-bottom: 10px">
      <!-- 添加按钮 -->
      <el-row style="text-align: left">
        <el-button
          type="primary"
          round
          @click="insertCategoryOpen"
          display="flex"
          >新增分类</el-button
        >
      </el-row>
    </div>

    <!-- 分类信息输入框 -->
    <el-dialog
      :visible="insertCategoryDialogVisible"
      title="添加分类"
      :before-close="handleCategoryDialogClose"
    >
      <el-form :model="categoryForm">
        <el-form-item label="分类名" prop="name" :rules="categoryFormRules.name">
          <el-input
            v-model.trim="categoryForm.name"
            placeholder="分类名不能为空"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCategoryDialogClose">取 消</el-button>
        <el-button type="primary" @click="insertCategorySubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-row>
      <el-col
        :span="ROW_CATEGORY_SIZE"
        v-for="(category, index) in categories"
        :key="category.id"
        :offset="index % ROW_CATEGORY_SIZE > 0 ? 2 : 0"
      >
        <CategoryCard
          :category="category"
          :updatetCategorySubmit="updatetCategorySubmit"
          :deleteCategorySubmit="deleteCategorySubmit"
          :updateCategoryActiveSubmit="updateCategoryActiveSubmit"
        ></CategoryCard>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { customAlphabet } from "nanoid";
import CategoryCard from "@/components/CategoryCard.vue";
import { triggerRef } from "vue";
export default {
  components: {
    CategoryCard,
  },
  data() {
    return {
      ROW_CATEGORY_SIZE: 4,
      PAGE_SIZE: 8,
      page: 1, // 当前页数
      categorySortName: "", // 当前categories中名称name筛选器默认值
      categorySortType: -1, // 当前categories中名称type筛选器默认值
      insertCategoryDialogVisible: false, // 是否新增分类信息输入框显示
      updateCategoryDialogVisible: false, // 是否修改分类信息输入框显示
      categories: [],
      categoryForm: {
        name: "", // 分类名称
        type: -1, // 分类类型
        sort: 0, // 用于排序分类权重
      },
      categoryFormRules: {
        name: [
          {
            required: true,
            message: "分类名不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 获取8位数字uuid作为分类类型
    getNumber8() {
      // 使用指定的字符集和长度生成ID
      const nanoid = customAlphabet("1234567890", 8);
      return parseInt(nanoid());
    },
    // 根据索引信息分页获取分类信息
    flashCategoryTable() {
      let url = `/admin/category/page?page=${this.page}&pageSize=${this.PAGE_SIZE}`;
      if (this.categorySortName !== "")
        url = url + `&name=${this.categorySortName}`;
      if (this.categorySortType !== -1)
        url = url + `&type=${this.categorySortType}`;
      // 发送请求获取数据
      this.axios
        .get(url)
        .then((response) => {
          console.log("Data received:", response.data);
          this.categories = response.data.data.records;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    // 关闭分类信息输入框
    handleCategoryDialogClose() {
      this.insertCategoryDialogVisible = false;
      this.updateCategoryDialogVisible = false;
      this.$message({
        type: "info",
        message: "已取消操作",
        duration: 1100,
      });
    },
    // 打开新增分类信息输入框
    insertCategoryOpen() {
      this.insertCategoryDialogVisible = true;
      this.categoryForm.name = "";
    },
    // 提交新增分类请求
    insertCategorySubmit() {
      if(this.categoryForm.name === "") return;
      this.insertCategoryDialogVisible = false; // 关闭输入框
      // 发送新增请求
      // 设置请求头
      const headers = {
        "Content-Type": "application/json",
      };
      // 设置请求体
      while (isNaN((this.categoryForm.type = this.getNumber8())));
      this.axios
        .post("/admin/category", this.categoryForm, { headers })
        .then((response) => {
          // 处理响应
          if (response.data.code === 1) {
            console.log("分类新增成功", response);
            this.$message({
              message: "添加成功",
              type: "success",
              duration: 1100,
            });
            // 更新分类信息表
            this.flashCategoryTable();
          } else if (response.data.code === 0) {
            console.log("分类新增失败");
            this.$message({
              message: "添加失败",
              type: "error",
              duration: 1100,
            });
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("请求失败:", error);
          this.$message({
            message: "请求错误",
            type: "error",
            duration: 1100,
          });
        });
    },
    // 提交修改分类请求
    updatetCategorySubmit(updateForm) {
      // 发送新增请求
      // 设置请求头
      const headers = {
        "Content-Type": "application/json",
      };
      // 设置请求体
      while (isNaN((this.categoryForm.type = this.getNumber8())));
      this.axios
        .put("/admin/category", updateForm, { headers })
        .then((response) => {
          // 处理响应
          if (response.data.code === 1) {
            console.log("分类修改成功", response);
            this.$message({
              message: "修改成功",
              type: "success",
              duration: 1100,
            });
            // 更新分类信息表
            this.flashCategoryTable();
          } else if (response.data.code === 0) {
            console.log("分类修改失败");
            this.$message({
              message: "修改失败",
              type: "error",
              duration: 1100,
            });
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("请求失败:", error);
          this.$message({
            message: "请求错误",
            type: "error",
            duration: 1100,
          });
        });
    },
    // 提交删除分类请求
    deleteCategorySubmit(deleteId) {
      // 发送删除请求
      // 设置请求头
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
      };
      // 设置请求体
      const params = { id: deleteId };
      this.axios
        .delete("/admin/category", { headers, params })
        .then((response) => {
          // 处理响应
          if (response.data.code === 1) {
            console.log("分类删除成功", response);
            this.$message({
              message: "删除成功",
              type: "success",
              duration: 1100,
            });
            // 更新分类信息表
            this.flashCategoryTable();
          } else if (response.data.code === 0) {
            console.log("分类删除失败");
            this.$message({
              message: "删除失败",
              type: "error",
              duration: 1100,
            });
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("请求失败:", error);
          this.$message({
            message: "请求错误",
            type: "error",
            duration: 1100,
          });
        });
    },
    // 提交修改启用/禁用分类请求
    updateCategoryActiveSubmit(updateId, newStatus) {
      // 设置请求头
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
      };
      // 设置请求体
      const params = { id: updateId };
      this.axios
        .post(`/admin/category/status/${newStatus}`, null, { headers, params })
        .then((response) => {
          // 处理响应
          if (response.data.code === 1) {
            console.log("分类状态修改成功", response);
            this.$message({
              message: "状态修改成功",
              type: "success",
              duration: 1100,
            });
            // 更新分类信息表
            this.flashCategoryTable();
          } else if (response.data.code === 0) {
            console.log("分类状态修改失败");
            this.$message({
              message: "状态修改失败",
              type: "error",
              duration: 1100,
            });
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("请求失败:", error);
          this.$message({
            message: "请求错误",
            type: "error",
            duration: 1100,
          });
        });
    },
  },
  mounted() {},
  watch: {
    page: {
      immediate: true,
      handler(value) {
        this.flashCategoryTable();
      },
    },
  },
};
</script>

<style>
</style>
