import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    adminId: -1, // 登录的员工ID
  },
  getters: {
  },
  mutations: {
    setAdminId(state, adminId) {
      state.adminId = adminId;
    },
  },
  actions: {
   
  },
  modules: {
  }
})
