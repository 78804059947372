<template>
  <div>
    <div>
      <!-- 商品筛选模块 -->
      <el-row
        style="text-align: left; margin-bottom: 10px"
        type="flex"
        align="middle"
      >
        <!-- 新增商品按钮 -->
        <el-col :span="2">
          <el-button
            type="primary"
            round
            @click="insertSnackDialogOpen"
            style="width: 100%; display: flex; justify-content: center"
            >新增商品</el-button
          >
        </el-col>

        <!-- 商品名字筛选输入框 -->
        <el-col :span="5" :offset="1">
          <!-- <div class="center-content"> -->
          <el-input
            placeholder="搜索商品名"
            v-model="snackSortName"
            @blur="flashSnackTable"
            @keyup.enter.native="enterBlur($event)"
            style="width: 100%"
            clearable
          >
          </el-input>
          <!-- </div> -->
        </el-col>
        <!-- 商品分类筛选菜单 -->
        <el-col :span="4" :offset="1">
          <Select
            :selectOptions="categoryOptions"
            v-on:select-option-click="handleSortCategorySelect"
            :handleClear="handleSortCategoryClear"
            :placeholder="'商品分类'"
            style="width: 100%"
          ></Select>
        </el-col>
        <!-- 商品状态筛选复选框 -->
        <el-col :span="3" :offset="1">
          <el-checkbox-group v-model="snackSortStatusCheckList">
            <el-checkbox
              label="在售"
              @change="handleSortStatus('在售')"
            ></el-checkbox>
            <el-checkbox
              label="停售"
              @change="handleSortStatus('停售')"
            ></el-checkbox>
          </el-checkbox-group>
        </el-col>
      </el-row>
    </div>

    <!-- 处理商品输入框 -->
    <el-dialog
      :visible="insertSnackDialogVisible || updateSnackDialogVisible"
      :title="insertSnackDialogVisible ? '添加商品' : '修改商品'"
      :before-close="handleSnackDialogClose"
      ref="snackDialog"
    >
      <el-form :model="snackForm" label-position="left">
        <el-form-item label="商品名称" prop="name">
          <el-input
            v-model="snackForm.name"
            placeholder="商品名称字数不超过15字"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类" prop="categoryName" style="text-align: left">
          <Select
            :selectOptions="categoryOptions"
            v-on:select-option-click="handleCategorySelect"
            :placeholder="'请选择分类'"
            ref="categorySelect"
          ></Select>
        </el-form-item>
        <el-form-item label="零食价格" prop="price">
          <el-input
            type="number"
            v-model="snackForm.price"
            placeholder="零食价格不为0"
          ></el-input>
        </el-form-item>
        <el-form-item label="零食描述" prop="description">
          <el-input
            v-model="snackForm.description"
            placeholder="零食描述"
          ></el-input>
        </el-form-item>
        <el-form-item label="售卖状态" prop="status" style="text-align: left">
          <el-tooltip>
            <el-switch
              v-model="snackForm.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="商品图片" prop="image">
          <UploadImage
            :fileChange="handleFileInsert"
            ref="uploadImage"
          ></UploadImage>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleSnackDialogClose">取 消</el-button>
        <el-button
          v-show="insertSnackDialogVisible"
          type="primary"
          @click="insertSnackSubmit"
          >确 定</el-button
        >
        <el-button
          v-show="updateSnackDialogVisible"
          type="primary"
          @click="updateSnackSubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 商品信息展示区 -->
    <el-table
      class="snack-table"
      :data="snacks"
      align="center"
      @row-click="updateSnackDialogOpen"
    >
      <!-- name 字段对应于商品名称 -->
      <el-table-column prop="name" label="商品名称" width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <!-- category_id 字段对应于分类名 -->
      <el-table-column
        prop="categoryName"
        label="分类"
        width="160"
        align="center"
      >
      </el-table-column>
      <!-- price 字段对应于零食价格 -->
      <el-table-column prop="price" label="零食价格" width="140" align="center">
      </el-table-column>
      <!-- image 字段对应于图片路径 -->
      <el-table-column prop="image" label="商品图片" width="240" align="center">
        <template slot-scope="scope">
          <el-image
            style="height: 65px; margin: 0px; padding: 0px"
            :src="scope.row.image"
            :preview-src-list="snackImageList"
            @click.stop="handleSnackImagePreview(scope.row.image)"
            fit="scale-down"
          ></el-image>
        </template>
      </el-table-column>
      <!-- description 字段对应于零食描述 -->
      <el-table-column
        prop="description"
        label="零食描述"
        width="220"
        align="center"
      >
      </el-table-column>
      <!-- status 字段对应于售卖状态 -->
      <el-table-column
        prop="status"
        label="售卖状态"
        width="140"
        align="center"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="" effect="dark">
            <span>在售</span>
          </el-tag>
          <el-tag v-if="scope.row.status === 0" type="danger" effect="dark">
            <span>停售</span>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" width="280" align="center">
        <template slot-scope="scope">
          <div>
            <el-button
              type="text"
              @click.stop="updateSnackDialogOpen(scope.row)"
              style="font-size: 15px; margin-right: 10px"
              >修改商品</el-button
            >
            <el-button
              type="text"
              @click.stop="deleteSnackById(scope.row.id)"
              style="font-size: 15px; margin-left: 10px"
              >删除商品</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页模块 -->
    <div style="text-align: center">
      <el-pagination
        layout="prev, pager, next"
        :total="snackTotal"
        :page-size="PAGE_SIZE"
        @prev-click="handlePrevPage"
        @next-click="handleNextPage"
        @current-change="handleChangePage"
        style="margin-top: 50px"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Select from "@/components/Select.vue";
import UploadImage from "@/components/UploadImage.vue";

export default {
  name: "SnackManage",
  components: {
    Select,
    UploadImage,
  },
  data() {
    return {
      PAGE_SIZE: 5,
      page: 1,
      snackTotal: 0,
      snackSortName: "", // 商品名筛
      snackSortStatus: -1, // 商品状态筛
      snackSortCategoryId: -1, // 商品分类筛
      categoryOptions: [], // 分类集合
      snackImageList: [""],
      snackSortStatusCheckList: ["在售", "停售"], // 商品状态筛选复选框
      snacks: [],
      snackForm: {
        id: -1,
        name: "",
        categoryId: "",
        price: 0,
        image: "",
        description: "",
        status: 0,
      },
      insertSnackDialogVisible: false,
      updateSnackDialogVisible: false,
    };
  },
  methods: {
    // 根据索引信息分页获取分类信息
    flashSnackTable() {
      let url = `/admin/snack/page?page=${this.page}&pageSize=${this.PAGE_SIZE}`;
      if (this.snackSortName !== "") url = url + `&name=${this.snackSortName}`;
      if (this.snackSortStatus !== -1)
        url = url + `&status=${this.snackSortStatus}`;
      if (this.snackSortCategoryId !== -1)
        url = url + `&categoryId=${this.snackSortCategoryId}`;
      // 发送请求获取数据
      this.axios
        .get(url)
        .then((response) => {
          console.log("Data received:", response.data);
          this.snacks = response.data.data.records;
          this.snackTotal = response.data.data.total;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    // 获取Category分类集合请求
    getCategoryOptions() {
      this.axios
        .get("/admin/category/list")
        .then((response) => {
          // 处理响应
          if (response.data.code === 1) {
            console.log("分类查询成功", response);
            this.categoryOptions = response.data.data;
          } else if (response.data.code === 0) {
            console.log("分类查询失败");
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("请求失败:", error);
          this.$message({
            message: "请求错误",
            type: "error",
            duration: 1100,
          });
          return [];
        });
    },
    // 关闭处理商品输入框
    handleSnackDialogClose() {
      this.insertSnackDialogVisible = false;
      this.updateSnackDialogVisible = false;
      this.$message({
        type: "info",
        message: "已取消操作",
        duration: 1100,
      });
    },
    // 新增商品弹框显示
    insertSnackDialogOpen() {
      this.snackForm.name = "";
      this.snackForm.categoryId = "";
      this.snackForm.price = 0;
      this.snackForm.image = null;
      this.snackForm.description = "";
      this.snackForm.status = 0;
      this.insertSnackDialogVisible = true;
      this.$nextTick(() => {
        // 同步分类
        this.$refs.categorySelect.value = "";
        // 同步图片
        this.$refs.uploadImage.handleRemove();
      });
    },
    // 新增商品请求
    insertSnackSubmit() {
      // 设置请求头
      const headers = {
        "Content-Type": "application/json",
      };
      // 设置请求体
      const body = {
        categoryId: this.snackForm.categoryId,
        description: this.snackForm.description,
        image: this.snackForm.image,
        name: this.snackForm.name,
        price: this.snackForm.price,
        status: this.snackForm.status,
      };
      // 关闭输入框
      this.insertSnackDialogVisible = false;
      // 发送请求
      this.axios
        .post("/admin/snack", body, { headers })
        .then((response) => {
          // 处理响应
          if (response.data.code === 1) {
            console.log("商品新增成功", response);
            this.$message({
              message: "添加成功",
              type: "success",
              duration: 1100,
            });
            // 更新分类信息表
            this.flashSnackTable();
          } else if (response.data.code === 0) {
            console.log("商品新增失败");
            this.$message({
              message: "添加失败",
              type: "error",
              duration: 1100,
            });
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("请求失败:", error);
          this.$message({
            message: "请求错误",
            type: "error",
            duration: 1100,
          });
        });
    },
    // 商品表单图片选择
    handleFileInsert(url) {
      this.snackForm.image = url;
    },
    // 修改商品弹框显示
    updateSnackDialogOpen(item) {
      console.log(item);
      this.snackForm.name = item.name;
      this.snackForm.categoryId = item.categoryId;
      this.snackForm.price = item.price;
      this.snackForm.id = item.id;
      this.snackForm.status = item.status;
      this.updateSnackDialogVisible = true;
      this.$nextTick(() => {
        // 同步分类
        this.$refs.categorySelect.value = item.categoryId;
        // 同步图片
        this.$refs.uploadImage.handleInsert(item.image);
      });
    },
    // 处理商品图片预览
    handleSnackImagePreview(image) {
      this.snackImageList = [];
      this.snackImageList.push(image);
    },
    // 修改商品请求
    updateSnackSubmit() {
      // 设置请求头
      const headers = { "Content-Type": "application/json" };
      // 设置请求体
      const body = {
        id: this.snackForm.id,
        categoryId: this.snackForm.categoryId,
        description: this.snackForm.description,
        image: this.snackForm.image,
        name: this.snackForm.name,
        price: this.snackForm.price,
        status: this.snackForm.status,
      };
      console.log("请求为：", body);
      // 关闭输入框;
      this.updateSnackDialogVisible = false;
      // 发送请求
      this.axios
        .put("/admin/snack", body, { headers })
        .then((response) => {
          // 处理响应
          if (response.data.code === 1) {
            console.log("商品修改成功", response);
            this.$message({
              message: "修改成功",
              type: "success",
              duration: 1100,
            });
            // 更新分类信息表
            this.flashSnackTable();
          } else if (response.data.code === 0) {
            console.log("商品修改失败");
            this.$message({
              message: "修改失败",
              type: "error",
              duration: 1100,
            });
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("请求失败:", error);
          this.$message({
            message: "请求错误",
            type: "error",
            duration: 1100,
          });
        });
    },
    // 删除商品请求
    deleteSnackById(deleteId) {
      this.$confirm("是否删除该商品记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 设置请求query
          const ids = deleteId + "";
          // 发送请求
          this.axios
            .delete("/admin/snack", { params: { ids: ids } })
            .then((response) => {
              if (response.data.code === 1) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                  duration: 1100,
                });
                this.flashSnackTable();
              } else if (response.data.code === 0) {
                this.$message({
                  type: "error",
                  message: "删除失败!",
                  duration: 1100,
                });
              }
            })
            .catch((error) => {
              // 处理错误
              console.error("请求失败:", error);
              this.$message({
                message: "请求错误",
                type: "error",
                duration: 1100,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "操作取消",
            duration: 1100,
          });
        });
    },
    // 处理商品输入框分类选择回调
    handleCategorySelect(event) {
      this.snackForm.categoryId = event.id;
    },
    // 处理商品筛选分类选择回调
    handleSortCategorySelect(event) {
      this.snackSortCategoryId = event.id;
    },
    //回车失去焦点
    enterBlur(event) {
      event.target.blur();
    },
    // 处理商品分类清空回调
    handleSortCategoryClear() {
      this.snackSortCategoryId = -1;
    },
    // 处理商品状态筛选回调
    handleSortStatus(label) {
      if (this.snackSortStatusCheckList.length == 0) {
        // 最少选一个
        this.snackSortStatusCheckList.push(label);
      }

      if (this.snackSortStatusCheckList.length === 2) this.snackSortStatus = -1;
      else {
        if (this.snackSortStatusCheckList.includes("在售")) {
          this.snackSortStatus = 1;
        } else if (this.snackSortStatusCheckList.includes("停售")) {
          this.snackSortStatus = 0;
        }
      }
      // 更新snakeTable
      this.flashSnackTable();
    },
    // 处理点击上一页
    handlePrevPage() {
      this.page--;
    },
    // 处理点击下一页
    handleNextPage() {
      this.page++;
    },
    // 处理点击指定页数
    handleChangePage(newPage) {
      this.page = newPage;
    },
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.flashSnackTable();
      },
    },
    snackSortCategoryId() {
      this.flashSnackTable();
    },
  },
  computed: {},
  mounted() {
    // 加载分类集合
    this.getCategoryOptions();
  },
};
</script>

<style lang="css">
/* 栅格布局中居中 */
.center-content {
  justify-content: center;
  align-items: center;
}

/* 仅选择tbody中的tr行 */
.snack-table tbody tr:hover {
  cursor: pointer;
}
/* 选择表头行 */
.snack-table thead tr {
  cursor: default; /* 或者保持默认样式 */
}
</style>

<!-- <style lang="scss" scoped>
.el-image-viewer__wrapper {
  img {
    width: auto !important;
  }
}
</style> -->