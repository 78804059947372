import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import Home from '@/views/Home'
import SnackManage from '@/views/SnackManage'
import AdminManage from '@/views/AdminManage'
import CategoryManage from '@/views/CategoryManage'
import OrderManage from '@/views/OrderManage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Login,
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    redirect: '/Home/SnackManage',
    props: true,
    children: [
      {
        path: 'SnackManage',
        component: SnackManage
      },
      {
        path: 'AdminManage',
        name: 'AdminManage',
        component: AdminManage
      },
      {
        path: 'CategoryManage',
        name: 'CategoryManage',
        component: CategoryManage
      },
      {
        path: 'OrderManage',
        name: 'OrderManage',
        component: OrderManage,
      }
    ]
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  console.log(to);
  console.log(from);
  next();
});

export default router
