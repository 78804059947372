<template>
  <body id="login-box" label-position="left">
    <el-form class="login-container" label-width="0px">
      <h2>系统登录</h2>
      <el-form-item label="">
        <el-input v-model="Loginform.loginName" placeholder="账号"></el-input>
      </el-form-item>
      <el-form-item label="" style="margin-bottom: 1px">
        <el-input
          v-model="Loginform.loginPassword"
          placeholder="密码"
          type="password"
        ></el-input>
      </el-form-item>
      <div style="margin-top: auto; text-align: right">
        <!-- <a href="" @click.prevent="goToRegister">去注册</a> -->
      </div>
      <el-form-item>
        <el-button type="primary" @click="login">登录</el-button>
      </el-form-item>
    </el-form>
  </body>
</template>

<script>
export default {
  data() {
    return {
      Loginform: {
        loginName: "",
        loginPassword: "",
      },
    };
  },
  methods: {
    goToRegister() {
      this.$router.push({ path: "/Register" });
    },
    login() {
      if (
        !(this.Loginform.loginName.trim() && this.Loginform.loginPassword.trim)
      ) {
        this.$message.error("用户名或密码不能为空");
        return;
      }
      // 校验账户密码是否正确
      // 设置请求头，指定发送 JSON 格式的数据
      const headers = {
        "Content-Type": "application/json",
      };
      // 设置请求体
      const data = {
        username: this.Loginform.loginName.trim(),
        password: this.Loginform.loginPassword.trim(),
      };
      this.axios
        .post("/admin/employee/login", data, { headers })
        .then((response) => {
          // 处理响应
          if (response.data.code === 1) {
            console.log("登录成功", response);
            this.$message({
              message: "登录成功",
              type: "success",
              duration: 1100,
            });
            // 保存JWT令牌
            localStorage.setItem("token", response.data.data.token);
            this.$store.commit('setAdminId', response.data.data.id);
            // 跳转主页
            this.$router.push({
              name: "Home",
            });
          } else if (response.data.code === 0) {
            console.log("登录失败");
            this.$message({
              message: "用户名或密码错误",
              type: "error",
              duration: 1100,
            });
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("登录请求失败:", error);
          this.$message({
            message: "请求错误",
            type: "error",
            duration: 1100,
          });
        });
    },
    // 防止浏览器回退
    disableBrowserBack() {
      //history.pushState(null, null, document.URL);
    },
  },
  mounted() {
    // 禁用浏览器返回键
    //history.pushState(null, null, document.URL);
    //window.addEventListener("popstate", this.disableBrowserBack);
  },
  beforeDestroy() {
    // 清除popstate事件 否则会影响到其他页面
    //window.removeEventListener("popstate", this.disableBrowserBack, false);
  },
};
</script>

<style scoped>
#login-box {
  background-position: center;
  height: 100%;
  width: 100%;
  background-size: cover;
  position: fixed;
}

body {
  margin: 0px;
  padding: 0px;
}

.login-container {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 90px auto;
  width: 360px;
  padding: 40px 40px 15px 40px;
  background-color: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cacac6;
}

.login-container button {
  margin-top: 15px;
  width: 80%;
  background-color: #505450;
  border: none;
}

.login-container button:active {
  background-color: #45444a;
}
</style>